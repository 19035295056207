// https://dev.to/roshan_100kar/display-dummy-api-json-data-to-a-table-in-react-js-3702
// https://www.digitalocean.com/community/tutorials/react-axios-react

// https://www.npmjs.com/package/date-fns

// https://reactcommunity.org/react-tabs/

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { format } from 'date-fns'
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";

import "../App.css";

import { API_URL } from '../config';



const PregledUnosa = () => {

    const navigate = useNavigate();

    const [vagaPodaci, getVagaPodaci] = useState([]);


    useEffect(() => {

        getPodaciVaga();

    }, []);





    const getPodaciVaga = async () => {

        //await axios.get(`${API_URL}/vaga`)
        await axios.get(`${API_URL}/getVaga`,
            {
                headers: { "Authorization": `${localStorage.getItem("user_tokenType")} ${localStorage.getItem("user_token")}` }
            })
            .then(res => {

                getVagaPodaci(res.data);
            })
    };



    const handleClickUnos = async (e) => {

        e.preventDefault();

        navigate("/Unos");
    };




    return (
        <div className="App" >

            <header className="App-header-unos">

                <br></br>                
                <Button style={{ width: 320, height: 40, fontWeight: 'bold' }} onClick={handleClickUnos} variant="primary">POVRATAK NA UNOS</Button>                
                <br></br>
                <br></br>

                <div>
                    <table className="table table-dark">

                        <thead>
                            <tr>
                                <th>Datum</th>
                                <th>Težina</th>
                                <th>Vrsta otpada</th>
                                {/*<th> </th>*/}
                            </tr>
                        </thead>

                        <tbody>

                            {
                                vagaPodaci.map(
                                    vagaPodaci =>
                                        <tr key={vagaPodaci.Id}>
                                            <td>{format(new Date(vagaPodaci.DatumVrijeme), 'dd.MM.yyyy. HH:mm:ss')}</td>
                                            <td>{vagaPodaci.Tezina}</td>
                                            <td>{vagaPodaci.VrstaOtpada}</td>
                                            {/*<td>
                                                <button onClick={() => this.editEmployee(vagaPodaci.Id)} className="btn btn-info">Update </button>
                                                <button style={{ marginLeft: "10px" }} onClick={() => this.deleteEmployee(vagaPodaci.Id)} className="btn btn-danger">Delete </button>
                                                <button style={{ marginLeft: "10px" }} onClick={() => this.viewEmployee(vagaPodaci.Id)} className="btn btn-info">View </button>
                                            </td>*/}
                                        </tr>
                                )
                            }

                        </tbody>
                    </table>
                </div>

            </header>

        </div>
    );
}

export default PregledUnosa;