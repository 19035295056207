/*import React  from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
//import Layout from "./pages/Layout";
import Pocetna from "./Pages/Pocetna";
import Unos from "./Pages/Unos";
import Login from "./Pages/Login";


import 'bootstrap/dist/css/bootstrap.min.css';



export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        
          <Route index element={<Pocetna />} />
          <Route path="Pocetna" element={<Pocetna />} />
          <Route path="Unos" element={<Unos />} />
          <Route path="Login" element={<Login />} />
        
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);*/





// https://www.youtube.com/watch?v=2k8NleFjG7I
// https://github.com/divanov11/React-router-v6-protected-routes


import React  from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

//import Pocetna from "./Pages/Pocetna";
import Login from "./Pages/Login";
import Unos from "./Pages/Unos";
import PregledUnosa from "./Pages/PregledUnosa";

import PrivateRoutes from "./Pages/PrivateRoutes";

import 'bootstrap/dist/css/bootstrap.min.css';



export default function App() {
  return (
    <BrowserRouter>
      
          <Routes>
            <Route element={<PrivateRoutes />}>
                <Route index element={<Login />} />
                <Route element={<Unos/>} path="Unos"/>
                <Route element={<PregledUnosa/>} path="PregledUnosa"/>                
            </Route>
            <Route element={<Login/>} path="Login"/>            
          </Routes>
      
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);