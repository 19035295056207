import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'


const PrivateRoutes = () => {
    
    const loggedInUser = localStorage.getItem("user_token");

    return(
        loggedInUser ? <Outlet/> : <Navigate to="Login"/>
    )
}

export default PrivateRoutes