// https://medium.com/how-to-react/react-select-dropdown-tutorial-using-react-select-51664ab8b6f3
// https://react-select.com/home
// https://betterprogramming.pub/how-to-use-async-await-with-axios-in-react-e07daac2905f

// https://www.makeuseof.com/redirect-user-after-login-react/

// https://www.digitalocean.com/community/tutorials/how-to-add-login-authentication-to-react-applications

import React, { useState, useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import "../App.css";

import { API_URL } from '../config';

import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';



const PrijavaKorisnika = () => {


    const navigate = useNavigate();

    const [loading, setloading] = useState();

    const [username, setUserName] = useState();
    const [password, setPassword] = useState();


    useEffect(() => {

        if (localStorage.getItem("user_token")) {
            navigate("/unos");
        }

    }, []);



    const handleClickPrijava = async (e) => {


        if (username != null && password != null) {

            e.preventDefault();


            setloading("click");


            const credentials =
            {
                username: username,
                password: password,
            }

            await axios.post(`${API_URL}/login`, credentials,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }

            ).then((response) => {

                localStorage.setItem("user_token", response.data.token);
                localStorage.setItem("user_tokenType", response.data.token_type);
                localStorage.setItem("user_Id", response.data.user_Id);

                //console.log("Login: " + localStorage.getItem("user_token"));

                navigate("/unos");
            })


                .catch((error) => {

                    if (error.response.status === 404) {

                        // https://gist.github.com/fgilio/230ccd514e9381fafa51608fcf137253

                        //console.log(error.response.data);
                        //console.log(error.response.status);
                        //console.log(error.response.headers);

                        toast.warning('Pogrešno korisničko ime ili lozinka !', {
                            position: toast.POSITION.TOP_CENTER
                        });


                        setloading();
                    }
                });

        }

        else if (username == null && password == null)

            toast.error('Podaci za prijavu nisu uneseni !', {
                position: toast.POSITION.TOP_CENTER
            });

    };






    return (
        <div className="App" >

            <ToastContainer theme="dark" />

            <header className="App-header-unos">

                <div className="center">

                    <div className="card bg-secondary text-black">
                        <div className="card-body">

                            <Form.Label style={{ fontFamily: "arial", fontSize: 15, fontWeight: 'bold', color: "white" }} >Korisničko ime</Form.Label>
                            <Form.Control type="text" onChange={e => setUserName(e.target.value)} />

                            <p></p>
                            <Form.Label style={{ fontFamily: "arial", fontSize: 15, fontWeight: 'bold', color: "white" }} >Lozinka</Form.Label>
                            <Form.Control type="password" onChange={e => setPassword(e.target.value)} />

                            <br></br>
                            <br></br>
                            <Button
                                style={{ width: 285, height: 50, fontWeight: 'bold' }}
                                onClick={handleClickPrijava}
                                disabled={loading ? (true) : null}
                                variant="danger">{loading ? (
                                    <Spinner
                                        as="span"
                                        variant="light"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        animation="border" />
                                ) : null} PRIJAVA KORISNIKA</Button>


                        </div>
                    </div>
                </div>

            </header>

        </div>
    );
};


export default PrijavaKorisnika;