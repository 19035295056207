// https://medium.com/how-to-react/react-select-dropdown-tutorial-using-react-select-51664ab8b6f3
// https://react-select.com/home
// https://betterprogramming.pub/how-to-use-async-await-with-axios-in-react-e07daac2905f

// https://codesandbox.io/s/competent-lalande-hgs1g?fontsize=14&hidenavigation=1&theme=dark&file=/src/App.js:325-332

import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "../App.css";

import { API_URL } from '../config';



const VagaUnos = () => {


    const navigate = useNavigate();

    const selectInputKamion = useRef();
    const selectInputVrstaOtpada = useRef();
    const selectInputCitaci = useRef();


    useEffect(() => {

        //console.log("Unos: " + localStorage.getItem("user_token"));

        getKamion();
        getVrstaOtpada();
        getCitaci();

    }, []);



    const [tezina, setTezina] = useState(0);

    const [SelectKamion, setSelectKamion] = useState(null);
    const [kamion, setKamion] = useState(null);

    const [SelectVrastaOtpada, setSelectVrstaOtpada] = useState(null);
    const [vrstaOtpada, setVrstaOtpada] = useState(null);

    const [SelectCitaci, setSelectCitaci] = useState(null);
    const [citac, setCitac] = useState(null);




    const handleChangeVaga = event => {
        setTezina(event.target.value);
    };



    const handleClickSpremi = async (e) => {

        e.preventDefault();

        if (kamion == null || vrstaOtpada == null || citac == null) {
            if (kamion == null) {
                toast.error('Niste odabrali kamion.', {
                    position: toast.POSITION.TOP_CENTER
                });
            }

            if (vrstaOtpada == null) {
                toast.error('Niste odabrali vrstu otpada.', {
                    position: toast.POSITION.TOP_CENTER
                });
            }

            if (citac == null) {
                toast.error('Niste odabrali čitač.', {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }

        else {
            e.preventDefault();

            const unosVaga =
            {
                FK_User: Number(localStorage.getItem('user_Id')),
                FK_Kamion: kamion,
                FK_VrstaOtpada: vrstaOtpada,
                FK_Citac: citac,
                Tezina: tezina,
            }


            await axios.post(`${API_URL}/vagaInsert`, unosVaga,
                {
                    headers: {
                        "Authorization": `${localStorage.getItem("user_tokenType")} ${localStorage.getItem("user_token")}`,
                        "Content-Type": 'application/json',
                    }
                })

                .then((response) => {

                    //console.log(response);

                    toast.success('Podaci su spremljeni.', {
                        position: toast.POSITION.TOP_CENTER
                    });

                    selectInputKamion.current.clearValue();
                    selectInputVrstaOtpada.current.clearValue();
                    selectInputCitaci.current.clearValue();

                    setKamion(null);
                    setVrstaOtpada(null);
                    setCitac(null);
                    setTezina(0);

                });
        };

    }



    /*function handleVagaGET() 
    {
        axios.get("http://localhost:8383/API_Vaga/public/api/getVagaSlanjeZadnje",         
        { 
            headers: {"Authorization" : `Bearer I07q1VAcCa3LRpUcRnVMXTrd1n3xZQc8mYuHAvOIjWtVKBhK8osWVFI8Rv2sfQRFh1CzowYVdcyAAtKEDqY7Ep9cCVdzmV3nfFmizJqq5f5OqGWSe1YbW5DuMeoPVzlwHYT5Fr5Gt6jUoWUEZwHAQnZbw9ht3Hp8RldUmjiEtUljopYegBQyqyXaxPxmrnemYHiPjoE3aEyXRCCvW8XGY64qLUUqI3QJbVZ8gcwsokJmN59vK6g7RfZqZ3pZExHT`} 
        })
  
        .then((response) => {

        setTezina (response.data.Tezina);
    });

    }*/



    const handleVagaGET = async () => {

        await axios.get(`${API_URL}/getVagaSlanjeZadnje`,
            {
                headers: { "Authorization": `${localStorage.getItem("user_tokenType")} ${localStorage.getItem("user_token")}` }
            })

            .then((response) => {

                setTezina(response.data.Tezina);

            });
    };




    /*function handleVagaGET2() 
    {
        axios.get('https://jsonplaceholder.typicode.com/users')
        
        .then((response) => {
                   

        let options = response.data.map(d => ({
            "value" : d.id,
            "label" : d.name          
        }))
        
        console.log(JSON.stringify(options));

        setSelectKamion (options);

    });

    }*/



    const getKamion = async () => {

        await axios.get(`${API_URL}/getKamion`,
            {
                headers: { "Authorization": `${localStorage.getItem("user_tokenType")} ${localStorage.getItem("user_token")}` }
            })

            .then((response) => {


                let options = response.data.map(d => ({
                    "value": d.Id,
                    "label": d.Registracija
                }))

                setSelectKamion(options);

            });
    };



    const getVrstaOtpada = async () => {

        await axios.get(`${API_URL}/getVrsteOtpada`,
            {
                headers: { "Authorization": `${localStorage.getItem("user_tokenType")} ${localStorage.getItem("user_token")}` }
            })

            .then((response) => {


                let options = response.data.map(d => ({
                    "value": d.Id,
                    "label": d.Naziv
                }))

                setSelectVrstaOtpada(options);

            });
    };



    const getCitaci = async () => {

        await axios.get(`${API_URL}/getCitac`,
            {
                headers: { "Authorization": `${localStorage.getItem("user_tokenType")} ${localStorage.getItem("user_token")}` }
            })

            .then((response) => {


                let options = response.data.map(d => ({
                    "value": d.Id,
                    "label": d.Oznaka
                }))

                setSelectCitaci(options);

            });
    };



    const handleChangeKamion = event => {

        if (event) {
            setKamion(event.value);
        }

    };


    const handleChangeVrstaOtpada = event => {

        if (event) {
            setVrstaOtpada(event.value);
        }

    };

    const handleChangeCitaci = event => {

        if (event) {
            setCitac(event.value);
        }

    };


    const onClear = () => {
        selectInputKamion.current.clearValue();
        selectInputVrstaOtpada.current.clearValue();
        selectInputCitaci.current.clearValue();
        setTezina(0);
    };



    const handleClickOdjava = async (e) => {

        e.preventDefault();

        /*localStorage.removeItem("user_token"); 
        localStorage.removeItem("user_tokenType"); 
        localStorage.removeItem("user_Id");*/

        localStorage.clear();

        navigate("/Login");
    };



    const handleClickPregledUnosa = async (e) => {

        e.preventDefault();

        navigate("/PregledUnosa");
    };




    /*
            if (!Token) {
            return <Navigate replace to="/login" />;
            } 
            else 
            {
    
    */

    return (

        <div className="App" >

            <ToastContainer theme="dark" />

            <header className="App-header-unos">

                <div className="center">

                    <div className="card bg-secondary text-black">
                        <div className="card-body">

                            <Form.Label style={{ fontFamily: "arial", fontSize: 15, fontWeight: 'bold', color: "white" }} >Kamion</Form.Label>
                            {/*<Select ref={selectInputRef} style={{fontFamily: "arial", fontSize: 12, color: "white"}} options={SelectKamion} onChange={event => {setKamion(event.value)}} placeholder='Odabir kamiona' />*/}
                            <Select ref={selectInputKamion} style={{ fontFamily: "arial", fontSize: 12, color: "white" }} options={SelectKamion} onChange={handleChangeKamion} placeholder='Odabir kamiona' />

                            <br></br>
                            <br></br>
                            <Form.Label style={{ fontFamily: "arial", fontSize: 15, fontWeight: 'bold', color: "white" }} >Vrsta otpada</Form.Label>
                            <Select ref={selectInputVrstaOtpada} style={{ fontFamily: "arial", fontSize: 12, color: "white" }} options={SelectVrastaOtpada} onChange={handleChangeVrstaOtpada} placeholder='Odabir vrste otpada' />

                            <br></br>
                            <br></br>
                            <Form.Label style={{ fontFamily: "arial", fontSize: 15, fontWeight: 'bold', color: "white" }} >Čitač</Form.Label>
                            <Select ref={selectInputCitaci} style={{ fontFamily: "arial", fontSize: 12, color: "white" }} options={SelectCitaci} onChange={handleChangeCitaci} placeholder='Odabir čitaća' />

                            <br></br>
                            <br></br>
                            <Form.Label style={{ fontFamily: "arial", fontSize: 15, fontWeight: 'bold', color: "white" }} >Težina (kg)</Form.Label>
                            <Form.Control type="number" onChange={handleChangeVaga} value={tezina} />

                            <br></br>
                            <Button style={{ width: 285, height: 40, fontWeight: 'bold' }} onClick={handleVagaGET} variant="warning">TEŽINA SA VAGE</Button>


                            <p></p>
                            <Button style={{ width: 285, height: 40, fontWeight: 'bold' }} variant="danger" onClick={onClear}>OŠISTI ODABIR</Button>

                            <p></p>
                            <Button style={{ width: 285, height: 40, fontWeight: 'bold' }} onClick={handleClickSpremi} variant="success">SPREMI</Button>

                        </div>
                    </div>

                    <p></p>

                    <Button style={{ width: 150, fontWeight: 'bold' }} onClick={handleClickOdjava} variant="secondary">ODJAVI SE</Button> {' '}
                    <Button style={{ width: 160, fontWeight: 'bold' }} onClick={handleClickPregledUnosa} variant="secondary">PREGLED UNOSA</Button> {' '}

                </div>


            </header>

        </div>
    );
};

//}
export default VagaUnos;